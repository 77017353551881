import React, {lazy, Suspense, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import { RedirectLogin } from "./services/Redirect";
import axiosConfig from "./services/axios-config";
const Feed = lazy(() => import("./views/Feed"));
const AddPost = lazy(() => import("./views/AddPost"));
const UpdatePost = lazy(() => import("./views/UpdatePost"));


const Router = (prop) => {
    
    useEffect(()=>{
        axiosConfig.post("https://api.cause.id/ajax/login.php",{
            useremail:"lius.alviando@cause.id",
            userpassword:"Test1234"
        }).then(res=>{
            localStorage.setItem("_a7as__tt56uid",res.data.data.user_id)
            localStorage.setItem("__xtropt",res.data.data.token)
        }).catch(err=>{
            // localStorage.setItem("_a7as__tt56uid",res.data.data.user_id)
            // localStorage.setItem("__xtropt",res.data.data.token)
        })
        if(!localStorage.hasOwnProperty){
            localStorage.setItem("_a7as__tt56uid",'1')
            localStorage.setItem("__xtropt","1231")
        }
        
    },[])
    return (
        <Suspense fallback="">
            <Routes>
                <Route path="/" element={(localStorage.hasOwnProperty('__xtropt') && localStorage.hasOwnProperty('_a7as__tt56uid')) ? <Feed/> : <RedirectLogin></RedirectLogin>} />
                {/* <Route path="/" element={ <Feed/> } /> */}
                <Route path="/add" element={<AddPost/>} />
                <Route path="/update" element={<UpdatePost/>} />
            </Routes>
        </Suspense>
    );
} 

export default Router;