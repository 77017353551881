import { hostUrl } from './Url';

export const RedirectLogin = (props) => {
    // window.location.href = `https://${hostUrl}/login?goto=feeds`;
    return (
        <div className="row my-5 mx-0">
            <div className="col-md-12 col-sm-12 text-center">
                <h6 className="text-danger">Please Wait ...</h6>
            </div>
        </div>
    )
}